import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { HeaderConsulta } from '@components/HeaderConsulta';
import { DadosCadastrais } from '@components/DadosCadastrais';
import { Preanamnese } from '@components/Preanamnese';
import { api } from '@services/api';
import { Receita } from '@components/Receita';
import { DadosClinicos } from '@components/DadosClinicos';
import { Jornadas } from '@components/Jornadas';
import BtnConfirmarTodas from '@assets/new-header/btn_confirmar_todas.svg';
import { Loading } from '@components/Loading';
import BtnReenviar from '../../assets/btn_reenviar.svg';

import { 
  getPacienteFromResponse, 
  getTransicoesFromResponse,
  getReceitaFromResponse,
  getJornadasFromResponse,
  hasReceita
} from '@utils/conversaoV2';
import { AppError } from '@utils/AppError';
import { askPermission } from '@utils/permission';
import { useConsulta } from '@hooks/useConsulta';
import { TbLoader } from "react-icons/tb";
import SeparadorImg from '../../assets/separador.png';

import {
  Container,
  ConsultasContainer,
  Loader,
  Cinza,
  Branco,
  Separador,
  BotaoConfirmarTudo,
  Form,
  BotaoReenviar
} from './styles';
import { ExecutarResponse } from '@dtos/ApiDTO';
import { useAuth } from '@hooks/useAuth';
import { TransicaoJornada } from '@components/TransicaoJornada';
import { InformacoesConsulta } from '@components/InformacoesConsulta';
import { preparaData } from '@utils/consultaHelper';

interface ConsultaProps {
  iniciar: boolean
}
export const Consulta = ({iniciar}: ConsultaProps) => {
  const [ loading, setLoading ] = useState(false);
  const [ loadingConfirmacao, setLoadingConfirmacao ] = useState(false);
  const [ assinaturaResponse, setAssinaturaResponse] = useState<ExecutarResponse>();
  const [ abriuLink, setAbriuLink] = useState(false);
  const [ openTransicaoJornada, setOpenTransicaoJornada ] = useState(false);

  const {     
    paciente,
    setPaciente,
    validarPaciente,

    receita,
    setReceita,
    validarReceita,

    jornadas,
    setJornadas,

    consultaData,
    setConsultaData,

    setEnviado,
    info,
    infoPronto
  } = useConsulta();

  let new_id = '';
  let { id } = useParams();
  const navigate = useNavigate();
  const isNew = id ? false : true;
  useEffect(() => {

    async function carregaConsulta() {
      var data;
      try {
        setLoading(true);
        if(isNew) {
          const response = await api.post("acessar");
          data = response.data;
          new_id = data.metadata.id;
        } else if(!iniciar) {
          const response = await api.post("acessar", { id });
          data = response.data;
        } else {
          const response = await api.post("acessar", { id, "transicao": "iniciar_consulta" });
          data = response.data;
        }
        data.isNew = isNew;
        setConsultaData(data);

        var paciente_new = getPacienteFromResponse(data);
        setPaciente(paciente_new)

        var receita = getReceitaFromResponse(data);
        setReceita(receita);

        var jornadas = getJornadasFromResponse(data);
        setJornadas(jornadas);

        localStorage.removeItem('lastupdate')
      
      } catch(error) {
        const isAppError = error instanceof AppError;
        const title = isAppError ? error.message : 'Não foi possível entrar. Tente novamente mais tarde.';
        toast.error(title);
      } finally {
        setLoading(false);
      }
    }

    askPermission();
    carregaConsulta();
 
  }, []);

  const { user } = useAuth();

  useEffect(() => {
    if(assinaturaResponse) {
      if(assinaturaResponse.hasOwnProperty("receita") && assinaturaResponse.receita?.status === "Sucesso") {
        setEnviado(true);
        if(!user.whatsappBusinessApi) { // se whatsapp_business_api veio false na autenticação, abre os links
          if(assinaturaResponse.jornadas?.linkWhatsappWeb) {
            if(!abriuLink) {
              window.open(assinaturaResponse.jornadas?.linkWhatsappWeb,'_blank');
              setAbriuLink(true)
            }
          } else if(assinaturaResponse.receita?.linkWhatsappWeb) {
            if(!abriuLink) {
              window.open(assinaturaResponse.receita?.linkWhatsappWeb,'_blank');
              setAbriuLink(true)
            }
          }
        }
      }
    }
  }, [assinaturaResponse]);


  const validarJornadas = (change_error = false) => {
    /*var erro_receita = erroReceita(receita);
    if(change_error) {
      setError({...error, ...erro_receita});
    }
    return Object.keys(erro_receita).length === 0;*/
    return true;
  };

  function handleVisualizar() {
    if(receita.metadata.urlPublico) {
      window.open(receita.metadata?.urlPublico,'_blank');
    }
  }  

  async function handleReenviar() {
    try {
      const response = await api.post("reenviar", {
        id: consultaData.metadata.id,
      });      
      const data = response.data;
      // se whatsapp_business_api veio false na autenticação, abre os links
      if(!user.whatsappBusinessApi) { 
        if(data.hasOwnProperty("receita") && data.receita?.status === "Sucesso") {
          if(data.receita?.linkWhatsappWeb) {
            window.open(data.receita?.linkWhatsappWeb,'_blank');
          }
        }
        if(data.hasOwnProperty("jornadas") && data.jornadas?.status === "Sucesso") {
          if(data.jornadas?.linkWhatsappWeb) {
            window.open(data.jornadas?.linkWhatsappWeb,'_blank');
          }
        }
      }
    } catch (error) {
      const isAppError = error instanceof AppError;    
      const title = isAppError ? error.message : 'Erro. Tente novamente mais tarde.';
      toast.error(title)
    }
  }

  async function handleConfimarTodas() {
    setLoadingConfirmacao(true);
    
    if(!infoPronto) {
      toast.error('Não está pronto para assinatura. Verifique as informações da consulta.');
      setLoadingConfirmacao(false);
      return;
    }
    const isPacienteValid = validarPaciente(true);
    if(!isPacienteValid) {
      toast.error('Não está pronto para assinatura. Verifique os dados cadastrais.');
      setLoadingConfirmacao(false);
      return;
    }

    if(hasReceita(receita)) {
      if(!validarReceita(true)) {
        toast.error('Não está pronto para assinatura. Verifique a receita.');
        setLoadingConfirmacao(false);
        return;
      }
    }

    if(validarJornadas(true)) {
      try {
        var data = preparaData(consultaData.metadata.id, info, paciente, receita, jornadas);
        const response = await api.post("executar", data);
        setAssinaturaResponse(response.data);

        const transicoes = getTransicoesFromResponse(consultaData);
        if (transicoes.some(e => e.id === 'encerrar_consulta')) {
          const response = await api.post("transicionar", {
            id: consultaData.metadata.id,
            transicao: 'encerrar_consulta',
          });
        }    
      } catch (error) {
        toast.error('Erro ao salvar os dados.')
      }
      navigate("/");
    } else {
      toast.error('Não está pronto para assinatura.');
    }
    setLoadingConfirmacao(false);
  }

  async function handleAlterarStatus(status) {
    try {
      const response = await api.post("transicionar", {
        id: id,
        transicao: status,
      });
    } catch (e) {
      toast.error('Não foi possível realizar a transição')
    }
    navigate("/");
  }

  function transicaoOpenClose() {
    setOpenTransicaoJornada(!openTransicaoJornada)
  }

  const nao_iniciou = ["3_scheduled", "2_checked-in"]; //"not_started"
  const agendada_ou_sala = consultaData?.payload ? nao_iniciou.includes(consultaData.payload.situacao.etapa) : false;

  return (  
  <Container>
    { openTransicaoJornada && 
      <TransicaoJornada onClose={transicaoOpenClose} handleAlterarStatus={handleAlterarStatus} />
    }
    {loading ? <Loader><TbLoader /></Loader> :
    <Form onSubmit={(e) => e.preventDefault()}>
      <Cinza>
        <HeaderConsulta id={id} transicaoOpenClose={transicaoOpenClose} />
        <ConsultasContainer>

          <InformacoesConsulta />
          
          <DadosCadastrais is_new={isNew} />

          <Preanamnese />

          {!agendada_ou_sala &&
          <>
            <Receita 
              receita={receita}
              handleVisualizar={handleVisualizar}
            />

            <DadosClinicos />
          </>
          }
        </ConsultasContainer>
      </Cinza>
      <Separador src={SeparadorImg} /> 
      <Branco>

        <Jornadas />
        
        {loadingConfirmacao 
          ?
            <BotaoConfirmarTudo><Loading /></BotaoConfirmarTudo>
          :
            <BotaoConfirmarTudo onClick={handleConfimarTodas}>
              <img src={BtnConfirmarTodas} />
            </BotaoConfirmarTudo>
        }
        <BotaoReenviar onClick={handleReenviar}>
          <img src={BtnReenviar} />
        </BotaoReenviar>

      </Branco>
    </Form>
    }
  </Container>
  );
};